/**
 * @file
 * Commerce modules
 *
 */

/* Checkout */
#commerce-checkout-form-checkout {
    @extend %narrowbody;
    @extend %ballsonsides;

    .cart_contents {
        .view {
            table {
                thead {
                    display: none;
                }
                td {
                    padding: 50px;
                    font-size: 240%;
                    line-height: 1.1;
                    font-weight: bold;
                    color: white;
                    background-color: $theme_blue;
                    @media screen and (max-width: $small_screen_width) {
                        padding: 15px;
                        font-size: 180%;
                    }
                }
            }
            .views-field-commerce-unit-price {
                display: none;
            }
            .views-field-quantity {
                display: none;
            }
            .views-field-commerce-total {
                width: 20%;
                border-left: 1px solid white;
            }
            .view-footer {
                display: none;
            }
        }
    }

    .commerce_payment {
        #edit-commerce-payment-payment-method {
            display: none;
        }
        .commerce-credit-card-expiration {
            select {
                width: auto;
            }
        }
        input.paymill-code {
            width: auto;
        }
    }

    fieldset#edit-legal {
        legend {
            font-size: 130%;
            font-weight: 900;
            color: $theme_grey_dark;
        }
    }
    .legal-data {
        li {
            font-size: 130%;
            line-height: 1.1;
            margin-bottom: .4em;
            color: $theme_grey_dark;
            a {
                color: $theme_grey_dark;
                text-decoration: underline;
            }
        }
    }

    .checkout-buttons {
        input[type="submit"] {
            font-size: 240%;
            &.checkout-continue {
                background-color: $theme_success;
                min-width: 320px;
                padding: 10px 20px;
                @media screen and (max-width: $micro_screen_width) {
                    min-width: 0;
                    width: 100%;
                }
            }
            &.checkout-cancel {
                color: $theme_error;
                font-weight: normal;
                @media screen and (max-width: $micro_screen_width) {
                    width: 100%;
                }
            }
        }
        .button-operator {
            font-size: 240%;
            padding: 0 5px 0 10px;
            @media screen and (max-width: $micro_screen_width) {
                display: none;
            }
        }
        p {
            margin-top: 20px;
            margin-bottom: 0;
        }
    }
}

#commerce-checkout-form-complete {
    @extend %narrowbody;
    @extend %ballsonsides;
}
