/**
* External Plugins CSS
*/
/* Slick */
@import "../node_modules/slick-carousel/slick/slick.scss";

.slick-slider {
    * {
        outline: none;
    }
}

.slick-dots {
    position: relative;
    top: -35px;
    height: 0;
    text-align: center;
    li {
        display: inline-block;
        margin-left: 5px;
        button {
            @include hide-text;
            padding: 0;
            width: 15px;
            height: 15px;
            border: 2px solid $theme_blue;
            border-radius: 50%;
            background: white;
        }
        &.slick-active {
            button {
                background: $theme_blue;
            }
        }
    }
}

/* Socicon */
[data-icon]:before {
    font-family: "socicon" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="socicon-"]:before,
[class*=" socicon-"]:before {
    font-family: "socicon" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* overlay */
@import "../node_modules/jquery-colorbox/example1/colorbox.css";
#cboxOverlay {
    background: black;
}

#cboxTopLeft, #cboxTopCenter, #cboxTopRight, #cboxMiddleLeft, #cboxMiddleRight, #cboxBottomLeft, #cboxBottomCenter, #cboxBottomRight {
    display: none;
}

#cboxContent {
    padding: 20px;
}

#cboxClose {
    overflow: hidden;
    width: 50px;
    height: 50px;
    bottom: auto;
    top: 0;
    background: none;
    &:before {
        @include iconize(-350px, -50px, 2, 2);
        content: " ";
    }
}
