/**
 * @file
 * Styles are organized using the SMACSS technique. @see http://smacss.com/book/
 *
 * When you turn on CSS aggregation at admin/config/development/performance, all
 * of these @include files will be combined into a single file.
 */

/* Import Sass mixins, variables, etc. */
@import "init";

/* HTML element (SMACSS base) rules */
@import "normalize";

/* Import fonts, mixins and plugins */
@import "fonts";
@import "mixins";
@import "plugins";

/* Component (SMACSS module) rules */
@import "components/misc";
@import "components/page";
@import "components/home";
@import "components/commerce";
