/**
 * @file
 * Home modules
 *
 */

// Programme filters
#block-views-home_programme_filters-block {
    background-color: $theme_grey_lighter;
    > .inner {
        @extend %wrapper;
        padding: 50px 0;
        @media screen and (max-width: $page_inner_maxwidth) {
            padding-left: $page_horizontal_padding;
            padding-right: $page_horizontal_padding;
        }
        @media screen and (max-width: $medium_screen_width) {
            padding-bottom: 50px;
        }

        > header {
            margin-bottom: 70px;
            @media screen and (max-width: $medium_screen_width) {
                margin-bottom: 20px;
            }
            h1 {
                font-size: 310%;
                line-height: 1;
                text-align: center;
            }
        }
    }

    .view {
        > header {
            position: relative;
            @media screen and (max-width: $medium_screen_width) {
                margin-bottom: 30px;
            }
            h1 {
                font-size: 1em;
                font-weight: normal;
                margin: 0;
            }
            p {
                @extend %narrowbody;
                font-size: 200%;
                text-align: center;
                margin-bottom: 3em;
            }
            a.download {
                position: absolute;
                top: -100px;
                right: 0;
                display: block;
                line-height: 1.4;
                color: black;
                font-size: 140%;
                width: 170px;
                &:hover {
                    color: black;
                    text-decoration: underline;
                }
                &:before {
                    @include iconize(0, 0);
                    content: " ";
                    float: left;
                    margin-right: 10px;
                }
                @media screen and (max-width: $medium_screen_width) {
                    @include one-columnize;
                    width: 170px;
                }
            }
        }
        .view-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            @media screen and (max-width: $small_screen_width) {
                display: block;
            }
            .views-row {
                margin: 0 30px 60px;
                @media screen and (max-width: $huge_screen_width) {
                    margin: 0 15px 30px;
                }
                @media screen and (max-width: $mediumbig_screen_width) {
                    margin: 0 10px 20px;
                }
                @media screen and (max-width: $small_screen_width) {
                    margin: 30px 0;
                }

                &.views-row-2, &.views-row-5, &.views-row-8 {
                    .views-field-name a {
                        &:hover {
                            background-color: $theme_yellow;
                        }
                    }
                }
                &.views-row-3, &.views-row-6, &.views-row-9 {
                    .views-field-name a {
                        &:hover {
                            background-color: $theme_red;
                        }
                    }
                }

                .views-view-fields {
                    width: 240px;
                    height: 240px;
                    @media screen and (max-width: $bigger_screen_width) {
                        width: 200px;
                        height: 200px;
                    }
                    @media screen and (max-width: $small_screen_width) {
                        margin: 0 auto;
                    }
                }

                .views-field-name {
                    height: 100%;
                    font-size: 170%;
                    line-height: 1.4;
                    font-weight: bold;
                    @media screen and (max-width: $mediumbig_screen_width) {
                        font-size: 150%;
                        line-height: 1.25;
                    }
                    .field-content {
                        display: table;
                        width: 100%;
                        height: 100%;
                    }

                    a {
                        display: table-cell;
                        width: 100%;
                        height: 100%;
                        padding: 0 10px;
                        border-radius: 50%;
                        border: 2px solid black;
                        color: black;
                        text-align: center;
                        vertical-align: middle;
                        background-color: white;
                        transition: all .3s ease;

                        &:hover {
                            background-color: $theme_blue;
                        }
                    }
                }
                .views-field-edit-term {
                    font-size: 140%;
                    a {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

// Subscription form
#block-webform-client-block-71 {
    text-align: center;
    body.front & {
        background-color: $theme_blue;
        color: white;
    }
    a {
        color: white;
    }
    > .inner {
        @extend %wrapper;
        max-width: 590px;
        padding-bottom: 50px;
        body.front & {
            padding-top: 100px;
            padding-bottom: 150px;
        }
        @media screen and (max-width: $medium_screen_width) {
            padding-top: 50px;
            padding-bottom: 50px;
        }
        > header {
            h1 {
                font-size: 290%;
                margin-bottom: .2em;
                @media screen and (max-width: $medium_screen_width) {
                    font-size: 220%;
                }
            }
        }
    }
    form {
        @extend %clearfix;
        .webform-component-markup {
            p {
                margin-bottom: 2em;
            }
        }
        .webform-component-email {
            float: left;
            width: 60%;
            margin-bottom: 0;
            @media screen and (max-width: $micro_screen_width) {
                @include one-columnize;
                position: relative;
                margin-bottom: 10px;
            }
            label {
                font-size: 260%;
                @media screen and (max-width: $medium_screen_width) {
                    font-size: 220%;
                }
                @media screen and (max-width: $small_screen_width) {
                    font-size: 180%;
                }
                .form-required {
                    display: none;
                }
            }
            input[type="email"] {
                font-size: 260%;
                @media screen and (max-width: $medium_screen_width) {
                    font-size: 220%;
                }
                @media screen and (max-width: $small_screen_width) {
                    font-size: 180%;
                }
            }
        }
        .form-actions {
            float: left;
            width: 40%;
            margin-bottom: 0;
            @media screen and (max-width: $micro_screen_width) {
                @include one-columnize;
                margin-bottom: 0;
            }
            input[type="submit"] {
                width: 100%;
                padding: 10px 20px;
                font-size: 260%;
                @media screen and (max-width: $medium_screen_width) {
                    font-size: 220%;
                }
                @media screen and (max-width: $small_screen_width) {
                    font-size: 180%;
                }
            }
        }
    }
}

// video playlist
#block-block-9 {
    > .inner {
        > .content {
            img {
                cursor: pointer;
                max-width: none;
                width: 100%;
            }
        }
    }
}
