/**
 * @file
 * Page modules
 *
 */

#page {
    position: relative;
    z-index: 1;
    overflow: hidden;
}

body.header-fixed {
    padding-top: $header-fixed-height;
}

#header {
    position: relative;
    z-index: 20;
    background-color: white;
    height: $header-height;
    border-bottom: 1px solid black;
    body.header-fixed & {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        height: $header-fixed-height;
    }
    @media screen and (max-width: $bigger_screen_width) {
        height: 94px;
    }
    @media screen and (max-width: $mediumbig_screen_width) {
        height: auto;
    }
    a {
        color: black;
        transition: all .3s ease;
        &:hover {
            color: $theme_red;
        }
    }
    > .inner {
        @extend %wrapper;
        position: relative;
        height: 100%;
        @media screen and (max-width: $mediumbig_screen_width) {
            padding: 10px 0;
        }
        .region-header {
            height: 100%;
        }
    }
}

.name-and-slogan {
    position: absolute;
    top: 8px;
    left: 50%;
    margin-left: -152px;
    width: 310px;
    text-align: center;
    z-index: 10;
    @media screen and (max-width: $mediumbig_screen_width) {
        @include one-columnize;
        margin-bottom: 10px;
    }
    h1.site-name {
        font-size: 570%;
        margin-bottom: 0;
        line-height: 1;
        transition: all .3s ease;
        body.header-fixed & {
            font-size: 360%;
        }
        @media screen and (max-width: $bigger_screen_width) {
            font-size: 480%;
        }
        @media screen and (max-width: $micro_screen_width) {
            font-size: 360%;
        }
    }
    .site-slogan {
        font-size: 220%;
        line-height: 1;
        body.header-fixed & {
            display: none;
        }
        @media screen and (max-width: $bigger_screen_width) {
            font-size: 180%;
        }
        @media screen and (max-width: $micro_screen_width) {
            font-size: 140%;
        }
    }
}

.region-header {

}

/* social links block */
#block-municilab-social_networks_links {
    position: absolute;
    top: 14px;
    right: 0;
    body.header-fixed & {
        display: none;
    }
    @media screen and (max-width: $page_inner_maxwidth) {
        right: $page_horizontal_padding;
    }
    @media screen and (max-width: $mediumbig_screen_width) {
        @include one-columnize;
        width: 192px;
        margin: 0 auto 10px;
    }
}

/* header intro */
#block-block-1 {
    position: absolute;
    top: 12px;
    left: 0;
    body.header-fixed & {
        display: none;
    }
    @media screen and (max-width: $page_inner_maxwidth) {
        left: $page_horizontal_padding;
    }
    @media screen and (max-width: $mediumbig_screen_width) {
        @include one-columnize;
        margin-bottom: 10px;
        text-align: center;
    }
    p {
        font-size: 140%;
        line-height: 1.25;
        margin-bottom: 0;
    }
}

/* language selector */
#block-locale-language {
    position: absolute;
    top: 12px;
    right: 220px;
    body.header-fixed & {
        display: none;
    }
    @media screen and (max-width: $mediumbig_screen_width) {
        right: $page_horizontal_padding;
    }
    @media screen and (max-width: $mobile_menu_min_width) {
        display: none;
    }
    ul {
        li {
            display: inline;
            margin-left: 5px;
            a {
                font-size: 140%;
                &.active {
                    display: none;
                }
            }
        }
    }
}

/* main menu */
#block-system-main-menu {
    position: absolute;
    width: 100%;
    bottom: 7px;
    left: 0;
    @media screen and (max-width: $huge_screen_width) {
        padding-left: $page_horizontal_padding;
        padding-right: $page_horizontal_padding;
    }
    @media screen and (max-width: $mobile_menu_min_width) {
        display: none;
    }
    nav {
        > ul.menu {
            @extend %clearfix;
            margin: 0;
            padding: 0;
            > li {
                position: relative;
                float: left;
                margin-right: 60px;
                list-style-type: none;
                list-style-image: none;
                &.right {
                    float: right;
                    margin-right: 0;
                    margin-left: 60px;
                    @media screen and (max-width: $huge_screen_width) {
                        margin-left: 30px;
                    }
                }
                @media screen and (max-width: $huge_screen_width) {
                    margin-right: 30px;
                }
                &:hover ul.menu {
                    display: block;
                }
                > a {
                    display: block;
                    color: black;
                    font-size: 220%;
                    padding: .4em 0;
                    &:hover {
                        color: $theme_red;
                    }
                    @media screen and (max-width: $bigger_screen_width) {
                        font-size: 180%;
                    }
                }
                &.active-trail > a, > a.active {
                    color: $theme_red;
                }
                ul.menu {
                    display: none;
                    position: absolute;
                    top: 51px;
                    left: -15px;
                    padding: 0 0 5px;
                    width: 200px;
                    background: white;
                    li {
                        a {
                            display: block;
                            padding: 2px 15px;
                            font-size: 180%;
                        }
                    }
                }
            }
        }
    }
}

/* responsive menu */
.mobile-menu {
    z-index: 2;
    position: fixed;
    top: 0;
    right: -100%;
    width: 92%;
    height: 100%;
    transition: all 1s ease;
    @media screen and (min-width: $mobile_menu_min_width) {
        display: none;
    }
    &.opened {
        right: 0;
        nav {
            opacity: 1;
        }
    }
    [data-action="open-mobile-menu"] {
        @include hide-text;
        position: fixed;
        top: 0;
        width: 50px;
        height: 50px;
        right: 0;
        outline: none;
        z-index: 2;
        background: none;
        border: none;
        transition: all .5s ease;
        &:before {
            @include iconize(-350px, 0, 2, 2);
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    &.opened [data-action="open-mobile-menu"] {
        &:before {
            background-position: -350px -50px;
        }
    }
    nav {
        opacity: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        z-index: 1;
        background: white;
        box-shadow: -5px 0 50px 0 rgba(0, 0, 0, 0.31);
        transition: all 1s ease;
        > ul.menu {
            > li {
                &.active-trail > a {
                    color: $theme_red;
                }
                a {
                    display: block;
                    font-size: 20px;
                    font-weight: 200;
                    padding: .4em 1.4em;
                    color: black;
                    &.active, &:active {
                        color: $theme_red;
                    }
                }
                ul.menu {
                    padding-left: 30px;
                }
            }
        }
    }
}

#content {
    position: relative;
    body.not-front & {
        padding: 50px 0 0;
        @media screen and (max-width: $medium_screen_width) {
            padding-top: 20px;
        }
        @media screen and (min-width: $bigger_screen_width) {
            min-height: 600px;
        }
    }
}

#sidebar-first {
    position: absolute;
    top: 50px;
    left: 0;
    width: 300px;
    @media screen and (max-width: $page_inner_maxwidth) {
        width: 20%;
    }
    @media screen and (max-width: $bigger_screen_width) {
        width: 17%;
    }
    @media screen and (max-width: $big_screen_width) {
        @include one-columnize;
        max-width: $body_width;
        > .inner {
            border-top: 1px solid black;
            padding-top: 20px;
        }
    }
}

.tabs-primary {
    @extend %wrapper;
    margin-bottom: 20px;
}

/* page or article title */
h1.page-title {
    font-size: 310%;
    line-height: 1.1;
    margin-bottom: 1em;
    @media screen and (max-width: $mediumbig_screen_width) {
        font-size: 240%;
    }
}

/* detail */
article.node-full {
    padding-bottom: 75px;

    .field-type-text-with-summary, .field-type-text-long {
        margin-bottom: 30px;
        p {
            a.btn {
                display: block;
                margin: 30px auto;
                width: 250px;
                transition: all .3s ease;
                @media screen and (max-width: $big_screen_width) {
                    width: 100%;
                }
                body.front & {
                    width: 350px;
                    margin: 10px auto 60px;
                    font-weight: bold;
                    font-size: 28px;
                    color: $theme_red;
                    padding: 10px;
                    text-align: center;
                    border: 2px solid $theme_red;
                    &:hover {
                        background-color: $theme_red;
                        color: white;
                    }
                    @media screen and (max-width: $big_screen_width) {
                        width: 100%;
                    }
                }
            }
        }
        h2 {
            font-size: 220%;
            line-height: 1.1;
            margin-bottom: .6em;
            padding-top: .5em;
            &:first-child {
                padding-top: 0;
            }
            @media screen and (max-width: $mediumbig_screen_width) {
                font-size: 180%;
                line-height: 1.2;
            }
        }
        h3 {
            font-size: 180%;
            line-height: 1.2;
            margin-bottom: .6em;
        }
        ul {
            padding-left: 23px;
            li {
                font-size: $base-font-size;
                line-height: $base-line-height;
                margin: 0 0 .5em;
                list-style: disc;
                @media screen and (max-width: $medium_screen_width) {
                    font-size: $base-font-size-smaller;
                }
            }
        }
        ol {
            li {
                font-size: $base-font-size;
                line-height: $base-line-height;
                margin: 0 0 .5em;
                @media screen and (max-width: $medium_screen_width) {
                    font-size: $base-font-size-smaller;
                }
            }
        }
        table {
            margin-bottom: 20px;
            th, td {
                font-size: 120%;
            }
        }
        iframe {
            margin: 30px 0;
        }
    }

    /* Home detail */
    body.front & {
        .field-type-text-with-summary, .field-type-text-long {
            @extend %narrowbody;
            p {
                font-size: 220%;
                line-height: 1.25;
                @media screen and (max-width: $mediumbig_screen_width) {
                    font-size: 180%;
                }
            }
        }
    }
    /* Page detail */
    &.node-page {
        body.front & {
            padding: 50px 0;
            overflow: hidden;
        }
        body.not-front & {
            @extend %narrowbody;
            @extend %ballsonsides;
        }
    }
    /* Activity detail */
    &.node-activity {
        .center-content {
            aside {
                margin-bottom: 20px;
                > header {
                    h2 {
                        font-size: 220%;
                        margin-bottom: 1em;
                    }
                }
            }
        }

        .date-and-type {
            font-size: 170%;
            padding: 0 0 7px;
            border-bottom: 1px solid black;
            margin-bottom: 20px;
            .day, .type {
                color: black;
                &:hover {
                    text-decoration: underline;
                }
            }
            .field-name-field-hour-start, .field-name-field-hour-end {
                display: inline;
                div {
                    display: inline;
                }
            }
            .field-name-field-hour-end {
                &:after {
                    content: "|";
                    padding: 0 10px;
                }
            }
        }

        h1.page-title {
            font-weight: normal;
            color: $theme_red;
        }

        .field-name-field-image {
            margin-bottom: 20px;
        }

        .more-info {
            @extend %clearfix;
            font-size: 170%;
            padding: 10px 0;
            margin-bottom: 20px;
            border-top: 1px solid black;
            border-bottom: 1px solid black;
            .field-name-field-speaker-ref, .field-name-field-languages {
                .field-item {
                    @include separate-by-commas;
                }
            }
            .where {
                @extend %clearfix;
                .field {
                    float: left;
                }
                .field-name-field-room {
                    &:before {
                        content: ",";
                        padding: 0 7px 0 0;
                        float: left;
                    }
                    .field-label {
                        font-weight: normal;
                    }
                }
            }
        }

        [data-action="enroll"] {
            margin-bottom: 20px;
        }

        .video-streaming {
            margin-bottom: 20px;
        }

        aside.links {
            .field {
                margin-bottom: 20px;
            }
        }

        .field-name-field-links-before, .field-name-field-docs-before {
            .field-item {
                font-size: 170%;
                margin-bottom: .3em;
                .file {
                    img {
                        display: none;
                    }
                }
                a {
                    color: black;
                    &:before {
                        @include iconize(0, 0);
                        content: " ";
                        float: left;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    /* Speaker detail */
    &.node-speaker {
        .field-name-field-person-job {
            font-size: 170%;
            font-weight: bold;
            margin-bottom: .3em;
        }
    }
}

/* home slider */
.view-slider-home {
    border-bottom: 1px solid black;
    .item-list {
        .slick-slider {
            overflow: hidden;
        }
    }
    .views-row {
        .node-banner {
            .field-name-field-banner-img {
                img {
                    width: 100%;
                }
            }
        }
    }
}

/* home CTA */
.view-home-cta {
    .item-list {

    }
    .views-row {
        .node-banner {
            .field-name-field-banner-img {
                img {
                    width: 100%;
                }
            }
        }
    }
}

/**** Programme ****/
body.page-programa, body.node-type-activity {
    #main {
        > .inner {
            @extend %wrapper;
            @media screen and (min-width: $big_screen_width) {
                min-height: 700px;
            }
        }
    }
    #content-inner {
        @extend %narrowbody;
        @extend %ballsonsides;
        &:before {
            display: none;
        }
        &:after {
            top: 20px;
        }
        @media screen and (max-width: $page_inner_maxwidth) {
            padding: 0;
        }
    }
}

/* programme intro */
#block-block-6 {
    margin-bottom: 30px;
    width: 210px;
    @media screen and (max-width: $bigger_screen_width) {
        width: auto;
    }
    > .inner {
        .content {
            p {
                font-size: 140%;
            }
            a.btn {
                color: black;
                &:before {
                    @include iconize(0, 0);
                    content: " ";
                    float: left;
                    margin: 3px 10px 0 0;
                }
            }
        }
    }
}

/* programme view */
.view-activities-programme {
    margin-bottom: 75px;
    .views-row {
        padding: 7px 0 35px;
        border-top: 1px solid $theme_grey_light;
        .views-view-fields {
            .views-field-field-activity-day, .views-field-field-hour-start, .views-field-field-hour-end {
                font-size: 140%;
            }
            .views-field-field-activity-type {
                font-size: 140%;
                &:before {
                    content: "|";
                    padding: 0 .3em;
                }
            }
            .views-field-title-field {
                font-size: 220%;
                line-height: 1.1;
                a {
                    color: $theme_red;
                }
            }
        }
    }
}

/* Tickets choose type page */
#block-views-choose_tickets-block {
    margin-bottom: 75px;
    > .inner {
        @extend %narrowbody;
        > header {
            h1 {
                font-size: 170%;
                font-weight: normal;
                margin-bottom: .3em;
            }
        }
    }
}

.view-choose-tickets {
    .view-content {
        @include simple-column-container;
        .views-row {
            @include simple-column(3, 4%);
            height: 330px;
            padding: 12px;
            border: 1px solid black;
            @media screen and (max-width: $body_width) {
                @include one-columnize;
                padding: 20px;
            }
            .node-ticket {
                position: relative;
                height: 100%;
                .price {
                    font-size: 190%;
                    line-height: 1.2;
                    font-weight: bold;
                    @media screen and (max-width: $body_width) {
                        font-size: 260%;
                        font-weight: normal;
                        float: left;
                        margin-right: 20px;
                    }
                }
                h2 {
                    font-size: 190%;
                    line-height: 1.2;
                    font-weight: bold;
                    @media screen and (max-width: $body_width) {
                        font-size: 260%;
                        font-weight: normal;
                        float: left;
                        margin-bottom: 15px;
                    }
                }
                .field-name-body {
                    position: absolute;
                    left: 0;
                    top: 90px;
                    width: 100%;
                    @media screen and (max-width: $body_width) {
                        @include one-columnize;
                        clear: both;
                    }
                    p {
                        font-size: 140%;
                        margin-bottom: 0;
                    }
                }
                .field-name-field-product-ref {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    @media screen and (max-width: $body_width) {
                        @include one-columnize;
                        margin-bottom: 0;
                    }
                    input[type="submit"] {
                        width: 100%;
                        font-size: 140%;
                        padding: 4px 6px;
                        color: white;
                        background-color: $theme_blue;
                    }
                }
            }
        }
    }
}

/* Inscription: extra info block */
#block-block-8 {
    > .inner {
        @extend %narrowbody;
    }
    .content {
        p {
            color: $theme_red;
            strong {
                text-transform: uppercase;
            }
        }
    }
}

/***** FOOTER *****/
#footer {
    position: relative;
    padding: 45px 0 10px;
    background-color: $theme_grey_darker;
    body.not-front {
        @media screen and (min-width: $page_inner_maxwidth) {
            margin-top: 100px;
        }
    }
    > .inner {
        @extend %wrapper;
    }
}

/* footer comuna logo */
#block-block-3 {
    width: 176px;
    margin: 0 auto;
    .content {
        a {
            opacity: .5;
            transition: all .3s ease;
            &:hover {
                opacity: 1;
            }
        }
    }
}

/* footer copy */
#block-block-4 {
    max-width: 590px;
    margin: 0 auto 40px;
    p {
        color: white;
        font-size: 160%;
        text-align: center;
        opacity: .5;
        @media screen and (max-width: $medium_screen_width) {
            font-size: 140%;
        }
        a {
            color: white;
            transition: all .3s ease;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

/* legal menu */
#block-menu-menu-legal {
    nav {
        ul.menu {
            margin: 0;
            padding: 0;
            text-align: center;
            li {
                display: inline;
                list-style-type: none;
                list-style-image: none;
                &:before {
                    content: '|';
                    padding: 0 10px;
                    color: white;
                    font-size: 160%;
                    opacity: .5;
                }
                &:first-child:before {
                    content: '';
                    padding: 0;
                }
                a {
                    color: white;
                    font-size: 160%;
                    opacity: .5;
                    transition: all .3s ease;
                    &:hover {
                        opacity: 1;
                        text-decoration: underline;
                    }
                    @media screen and (max-width: $medium_screen_width) {
                        font-size: 140%;
                    }
                }
                &.active-trail a, a.active {
                    opacity: 1;
                    text-decoration: underline;
                }
            }
        }
    }
}

/* cookies sliding popup */
#sliding-popup {
    .popup-content {
        #popup-text {
            padding: 20px 0;
            margin: 0;
            h2 {
                font-weight: normal;
                font-size: 16px;
            }
        }
    }
}
